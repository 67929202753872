<template>
  <div>
    <b-card-actions
      ref="cardAction"
      title="Filters"
      @remove="resetColFilters"
    >
      <b-row>
        <b-col
          cols="12"
          md="2"
          class="mb-1"
        >
          <vc-date-picker
            v-model="start"
            mode="dateTime"
            :model-config="modelConfig"
            is24hr
          >
            <template v-slot="{ inputValue, inputEvents }">
              <label>From date</label>
              <input
                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300 form-control"
                :value="inputValue"
                placeholder="From date"
                v-on="inputEvents"
              >
            </template>
          </vc-date-picker>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-1"
        >
          <vc-date-picker
            v-model="end"
            mode="dateTime"
            :model-config="modelConfig"
            is24hr
          >
            <template v-slot="{ inputValue, inputEvents }">
              <label>To date</label>
              <input
                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300 form-control"
                :value="inputValue"
                placeholder="To date"
                v-on="inputEvents"
              >
            </template>
          </vc-date-picker>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2 mt-2"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="handleSearch"
          >Search
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Wallet Summary</h5>
      </b-card-header>
      <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".28"
        rounded="sm"
      >
        <b-table-simple
          hover
          striped
          caption-top
          responsive
          class="rounded-bottom mb-0"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th>Count</b-th>
              <b-th>MRP [MYR]</b-th>
              <b-th>Amount [MYR]</b-th>
              <b-th>Type of Transaction</b-th>
              <b-th>Type</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-if="list.length > 0">
              <b-tr
                v-for="(tr, indextr) in list"
                :key="indextr"
              >
                <b-td>
                  {{ tr.Rows }}
                </b-td>
                <b-td>{{ tr.org_amt }}</b-td>
                <b-td>{{ tr.amt }}</b-td>
                <b-td>{{ tr.maintype }}</b-td>
                <b-td>
                  <b-badge
                    v-if="tr.type == 'D'"
                    :variant="getOrderStatusColor(tr.type)"
                  >
                    Debited
                  </b-badge>
                  <b-badge
                    v-if="tr.type == 'C'"
                    :variant="getOrderStatusColor(tr.type)"
                  >
                    Credited
                  </b-badge>
                </b-td>
              </b-tr>
            </template>
            <template v-else>
              <b-tr rowspan="10">
                <b-td
                  colspan="5"
                  class="text-center"
                >
                  No data avilable
                </b-td>
              </b-tr>
            </template>
          </b-tbody> </b-table-simple><br>
        <b-table-simple
          hover
          striped
          caption-top
          responsive
          class="rounded-bottom mb-0"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th>Opening Balance in {{ start }}</b-th>
              <b-th>Total Credited in {{ start }}:{{ end }}</b-th>
              <b-th>Total Debited in {{ start }}:{{ end }}</b-th>
              <b-th>Closing Balance in {{ end }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-if="list.length > 0">
              <b-tr>
                <b-td>
                  {{ openBalnace }}
                </b-td>
                <b-td>{{ totalCredited }}</b-td>
                <b-td>{{ totalDebited }}</b-td>
                <b-td>{{ closingBalance }}</b-td>
              </b-tr>
            </template>
            <template v-else>
              <b-tr rowspan="10">
                <b-td
                  colspan="4"
                  class="text-center"
                >
                  No data avilable
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
      </b-overlay>
    </b-card>
  </div>
</template>
<script>
/* eslint-disable import/no-unresolved */
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BTableSimple,
  BCard,
  BCardHeader,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BRow,
  BCol,
  BButton,
  BBadge,
  BOverlay,
}
  from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserReportService from '../../services/user.report.service'

export default {
  components: {
    BTableSimple,
    BCard,
    BCardHeader,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BRow,
    BCol,
    BButton,
    BBadge,
    BOverlay,
    BCardActions,
  },
  directives: {
    Ripple,
  },
  data() {
    const date = new Date()
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    return {
      date: '',
      selected: [],
      status: 'All',
      itemsPerPage: 25,
      isMounted: false,
      showLoading: false,
      summary: [],
      start: `${date.getFullYear()}-${date.getMonth() + 1}-01  00:00`,
      end: `${date.getFullYear()}-${date.getMonth() + 1}-${lastDay.getDate()}' 23:59:59'`,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm:ss', // Uses 'iso' if missing
      },
      openBalnace: '',
      closingBalance: '',
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    list() {
      return this.summary
    },
    queriedItems() {
      return this.totalItems
    },
    totalCredited() {
      return this.summary.reduce((total, item) => {
        if (item.type === 'C') {
          return total + parseInt(item.org_amt, 10)
        }
        return total
      }, 0)
    },
    totalDebited() {
      return this.summary.reduce((total, item) => {
        if (item.type === 'D') {
          return total + parseInt(item.org_amt, 10)
        }
        return total
      }, 0)
    },
  },
  mounted() {
    this.isMounted = true
    this.getWalletSummary()
  },
  methods: {
    getOrderStatusColor(status) {
      if (status === 'C') return 'light-success'
      if (status === 'D') return 'light-danger'
      return 'success'
    },
    changeLenght(data) {
      this.itemsPerPage = data
      this.getWalletSummary({
        limit: this.itemsPerPage,
      })
    },
    handleSearch() {
      if (this.start && this.end) {
        this.date = `${this.start}#${this.end}`
        if (this.start < this.end) {
          this.getWalletSummary({
            date: this.date,
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: 'Please change start and end date',
              icon: 'MessageSquareIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Please Select date or day',
            icon: 'MessageSquareIcon',
            variant: 'danger',
          },
        })
      }
    },
    handleChangePage() {
      this.getWalletSummary({
        page: this.userData.current_page,
        date: this.date,
      })
    },
    resetColFilters() {
      this.start = new Date()
      this.end = new Date(
        this.start.getFullYear(),
        this.start.getMonth() + 1,
        0,
      )
      this.getWalletSummary()
      this.showLoading = false
      this.$refs.cardAction.showLoading = false
    },
    getWalletSummary(data) {
      this.showLoading = true
      UserReportService.getWalletBalanceSummary(data).then(
        response => {
          this.summary = response.walletBalance
          this.openBalnace = response.openBalnce
          this.closingBalance = response.closingBalance
          this.showLoading = false
        },
        () => {
          this.showLoading = false
        },
      )
    },
  },
}
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card {
    ::v-deep .card-header {
        .heading-elements {
            position: static;
            cursor: inherit;

            .list-inline {
                display: block;

                li {
                    a {
                        padding: 0;
                    }

                    &:not(:last-child) {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}

.dark-layout {
    .b-overlay-wrap ::v-deep .b-overlay {
        // border: 10px solid red;
        .bg-white {
            background-color: $theme-dark-body-bg !important;
        }
    }
}
</style>
